.btn-primary:disabled, .btn-primary.disabled {
    color: darkgray;
    background-color: lightgray;
    border-color: lightgray;
}

th {
    white-space: nowrap;
    vertical-align: middle;
}

.td-button {
    justify-content: center;
    text-align: center;
    vertical-align: middle;
}

.sort-button {
    border-radius: 50%;
    background-color: transparent;
    border-color: transparent;
    vertical-align: middle;
    margin-left: 10px;
}

.sort-button:hover {
    border-radius: 50%;
    background-color: lightgray;
    border-color: darkgray;
    vertical-align: middle;
    margin-left: 10px;
}

.hover-text {
    background-color: darkgray;
    color: black;
}

.background-title {
    background: url(../images/background_title.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.background-simple {
    background: url(../images/background_simple.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.background-simple-flip {
    background: url(../images/background_simple_flip.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.background-plain {
    background: url(../images/background.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.background-flip {
    background: url(../images/background_flip.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.background-ring {
    background: url(../images/background_left_ring.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.background-navigation {

    background: url(../images/background_navigation.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

body {
    background-color: transparent !important;
}

/**/
.text-dark {
    color: #ac873a !important;
    font-size: 20px;
}

body {
    font-family: Gill Sans,Gill Sans MT, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.colorBlue {
    color: rgb(24, 117, 148);
}
.colorBrightBlue {
    color: rgb(17, 86, 120);
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #e4c991;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    font-weight: bold;
}

    .nav-link:hover {
        color: #ac873a;
    }


.form-control {
    /*background-color: transparent; */
}

select {
    background-color: transparent;
}

.tableBorder {
    border: 1px solid;
}
.tableCSS{
    margin-bottom: 1rem;
}
.tableHeader {
    font-style: italic;
}

.question {
    font-style: italic;
}
.answer {
    margin-bottom: 30px;
    line-height: 2;
}

.header {
    font-weight: bold;
}

.verno {
    font-size: 12px;
}

.underline {
    text-decoration: underline;
}
.games-dropdown-3{
    width: 180px;
}

.modal {
    display: none;
    position: fixed;
    z-index: 1051;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.login-content {
    margin-top: 20px;
    max-width: 400px;
    width: 400px;
    float: none;
    margin: 0 auto;
    background-color: white;
    padding-bottom: 10px;
    border-radius: 5px;
    border: 1px gray solid;
}

.forgot-link {
    font-size: 0.8rem;
}

.form-group {
    margin-bottom: 1rem;
}

.required {
    color: red;
}

.checkboxWithLabel {
    margin-left: 5px;
    margin-right: 10px;
}